






















































































































































import Vue from 'vue';
import $ from 'jquery';
import {gsap, TimelineLite} from 'gsap';
import {mapActions, mapGetters} from 'vuex';
import {CurrentStepInfo, StepInfo, SubStepInfo} from '@/types/step';
import Overlay from '@/components/Overlay.vue';
import {OptionInfo} from '@/types/option';
import {MediaItem, MediaItemDict, MediaType} from '@/types/core';
import SocialShare from '@/components/SocialShare.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Preload = require('preload-it');

interface Data {
    optionClicked: boolean;
    imagesSet: boolean;
    nextAssetsLoaded: boolean;
    showOverlay: boolean;
    currentSubStep?: SubStepInfo;
    videoIntro: HTMLMediaElement | null;
    videoLoop: HTMLMediaElement | null;
    mediaCache: MediaItemDict,
    clickEventOverlayVisible: boolean,
    fromStep: string,
    toStep: string,
}

export default Vue.extend({
    name: 'Step',
    components: {
        Overlay,
        SocialShare,
    },
    props: {
        stepNo: {
            type: String,
            default: '',
        },
    },
    data(): Data {
        return {
            optionClicked: false,
            imagesSet: false,
            nextAssetsLoaded: false,
            showOverlay: false,
            videoIntro: null,
            videoLoop: null,
            mediaCache: {},
            clickEventOverlayVisible: false,
            fromStep: '',
            toStep: '',
        };
    },
    /**
     * This route function is called when the app is entered or a (hard) refresh was done
     **/
    async beforeRouteEnter(to, from, next) {
        // eslint-disable-next-line
        next(async (vm: any) => {
            vm.fromStep = from.params['stepNo'];
            vm.toStep = to.params['stepNo'];
            vm.clickEventOverlayVisible = !vm.enteredApp;

            gsap.set([vm.$refs.step], {autoAlpha: 0});
            await vm.preloadAssets(vm.toStep)
                .then(() => {
                    // enable the game (start button) when the videos of the first step are loaded
                    vm.setGameEnabled(true);
                    // trigger the resize event, so the video content box is sized correctly
                    vm.$parent.handleResize();
                })
            next();
        });
    },
    /**
     * This router function is called before a route change, by click events or url navigation
     **/
    async beforeRouteUpdate(to, from, next) {
        this.showOverlay = false;

        await this.stepChange(to.params['stepNo'], from.params['stepNo']);

        const vid = this.videoIntro;
        if (vid) {
            vid.pause();

            setTimeout(() => {
                let playPromise = vid.play();
                this.introAnimation();
                if (playPromise !== undefined) {
                    playPromise
                        .finally(() => {
                            this.preloadNextStep()
                                .then(() => {
                                    this.nextAssetsLoaded = true;
                                })
                        })
                        .catch((err) => {
                            console.error('error in playPromise: ', err);
                        })
                }
            }, 20)
            this.setImages();
        }

        next();
    },
    computed: {
        ...mapGetters('game', ['getStep', 'enteredApp', 'audioEnabled']),
        ...mapGetters('currentStep', ['currentStepInfo']),
        ...mapGetters('currentSubStep', ['currentSubStepInfo']),

        optionModifierClasses(): Record<string, boolean> {
            return {
                'c-step__option u-button': true,
                'c-step__option u-button multiple': (this?.options?.length ?? 0) > 1,
            };
        },
        stepContentModifierClasses(): Record<string, boolean> {
            const rec: Record<string, boolean> = {};
            rec['c-step__content'] = true;
            rec[`c-step__content--${(this?.step.layout ?? 'bottom')}`] = true;
            return rec;
        },
        step(): StepInfo {
            return this.getStep(this.stepNo);
        },
        text(): string {
            return this.currentSubStepInfo.text ?? this.step.text;
        },
        title(): string {
            return this.currentSubStepInfo.title ?? this.step.title;
        },
        options(): OptionInfo[] {
            return this.currentSubStepInfo.options ?? this.step.options;
        },
    },
    watch: {
        /**
         * When entering the app, the videos of the step are already preloaded and this function is called
         **/
        async enteredApp(): Promise<void> {
            if (this.enteredApp) {
                this.stepChange(this.toStep, this.fromStep);
                const vid = document.querySelector('.vidIntro:not(.preload)') as HTMLMediaElement;
                this.videoIntro = vid;
                this.videoLoop = document.querySelector('.vidLoop:not(.preload)') as HTMLMediaElement;

                this.handleVideoPlaying();
                this.setImages();
                this.introAnimation();

                if (vid) {
                    vid.pause();
                    vid.play().catch((err) => {
                        console.error('err: ', err)
                    });
                }

                this.preloadNextStep()
                    .then(() => {
                        this.nextAssetsLoaded = true;

                        // Toggle the audio once. This fixes the problem om IOS that audio does not play in some cases.
                        // See Todo.md for more info.
                        this.setAudioEnabled(false);
                        setTimeout(() => {
                            this.setAudioEnabled(true);
                        }, 20);
                    });
            }
        },
    },
    updated() {
        this.setImages();
    },
    methods: {
        ...mapActions('currentStep', ['setCurrentStep']),
        ...mapActions('currentSubStep', ['setCurrentSubStep']),
        ...mapActions('game', ['setGameEnabled', 'setAudioEnabled']),

        // region form element events
        onClickOption(nextStep: string) {
            this.optionClicked = true;
            if (nextStep.startsWith('popup')) {
                this.setShowOverlay();
                this.optionClicked = false;
            } else if (this.step.subSteps?.[nextStep] !== undefined) {
                this.subStepFadeOut()
                    .then(() => {
                        this.setCurrentSubStep(this.step.subSteps?.[nextStep])
                            .then(() => {
                                this.subStepFadeIn();
                            })
                    });
            } else {
                this.routerNavigateNext(nextStep);
            }
        },
        // endregion

        setShowOverlay() {
            this.showOverlay = true;
        },

        getOptionLabel(index: number) {
            const options: string[] = ['A', 'B', 'C'];
            if (index > options.length) {
                return '-';
            }
            return options[index];
        },

        // region Animations
        async startScreenIntroAnimation(tl: TimelineLite) {
            tl.to('.c-step__boer', {duration: 0.5, autoAlpha: 1}, 0);
            tl.from('.c-step__game', {
                duration: 0.7, scaleX: 2.3,
                scaleY: 2.3, rotation: -720, transformOrigin: '50% 50%',
            }, 0.1);
            tl.to('.c-step__game', {duration: 0.5, autoAlpha: 1}, 0);
            tl.from('.c-step__kiespijn', {duration: 0.3, scaleX: 2.3}, 0.5);
            tl.to('.c-step__kiespijn', {duration: 0.3, autoAlpha: 1}, 0.5);
            tl.to('.c-step__tak', {duration: .4, autoAlpha: 1}, 0.7);
        },

        hideAll() {
            gsap.set('.c-background-content, .c-step__option', {autoAlpha: 0});
            if (this.$router.currentRoute.path === '/stap/start') {
                gsap.set('.c-step__game, .c-step__kiespijn, .c-step__tak, .c-step__boer', {autoAlpha: 0});
            }
            if (this.text) {
                gsap.set('.c-step__text', {autoAlpha: 0});
            }
            if (this.step.type === 'end') {
                gsap.set(this.$refs.refShare, {autoAlpha: 0});
            }
            gsap.set('.c-step__option', {left: '-=350'});
        },

        async introAnimation(): Promise<void> {
            let tl = gsap.timeline({paused: true});

            this.hideAll();

            if (this.$router.currentRoute.path === '/stap/start') {
                this.startScreenIntroAnimation(tl);
            }

            // first show video
            // todo : background-content of step weg?
            tl.to('.c-background-content', {duration: 0.01, delay: 0, autoAlpha: 1}, 0);
            tl.to([this.$refs.step], {duration: 0.01, autoAlpha: 1}, 0);

            // then the rest
            const offset = this.step.introAnimOffset ?? 0.7;
            if (this.text) {
                tl.to('.c-step__text', {duration: 0.5, delay: 0, autoAlpha: 1}, offset + 0.5);
            }

            tl.to('.c-step__option', {
                    duration: 0.2, delay: 0, autoAlpha: 1, stagger: 0.2,
                    ease: 'power2.in'},
                offset + (this.text ? 1 : 0.5));
            tl.to('.c-step__option', {
                    duration: 0.2, delay: 0, left: 0, stagger: 0.2,
                    ease: 'power2.out',
                },
                offset + (this.text ? 1 : 0.5)
            );

            if (this.step.type === 'end') {
                tl.to(this.$refs.refShare, {duration: 0.25, autoAlpha: 1}, 3);
                gsap.to(this.$refs.refShareLabel, {
                    duration: 0.15,
                    x: '-=5',
                    yoyo: true,
                    repeat: 5,
                    repeatDelay: 0.1,
                    delay: 4.5,
                });
            }

            tl.restart();
        },

        async startScreenOutroAnimation(): Promise<void> {
            const tl = gsap.timeline({});
            tl.to('.c-step__tak', {duration: .4, autoAlpha: 0}, 0);
            tl.to('.c-step__game', {
                duration: 0.7, autoAlpha: 0,
            }, 0.5);
            tl.to('.c-step__kiespijn', {duration: 0.3, autoAlpha: 0}, 0.5);
            tl.to('.c-step__boer', {duration: 0.3, autoAlpha: 0}, 0.5);
        },

        async outroAnimation(quickOne = false): Promise<void> {
            const tl = gsap.timeline({
                delay: quickOne ? 0 : 0.3,
                onComplete: () => {
                    gsap.set([this.$refs.step], {autoAlpha: 0});
                    if (this.text) {
                        gsap.set('.c-step__text', {autoAlpha: 0});
                    }
                    gsap.set('.c-background-content, .c-step__option', {autoAlpha: 0});
                },
            });

            if (this.$router.currentRoute.path === '/stap/start') {
                await this.startScreenOutroAnimation();
            }

            if (this.step.popup) {
                tl.to('.c-step__overlay', {duration: 0.25, delay: 0, autoAlpha: 0}, 0);
            }

            if (!quickOne) {
                if (this.text) {
                    tl.to('.c-step__text', {duration: 0.5, delay: 0, autoAlpha: 0}, 0.25);
                }
                tl.to('.c-step__option', {duration: 0.1, delay: 0, autoAlpha: 0, stagger: 0.2}, 0.3);
                tl.to('.c-step__option', {duration: 0.1, delay: 0, left: '+=750', stagger: 0.2}, 0.3);
            } else {
                if (this.text) {
                    tl.set('.c-step__text', {autoAlpha: 0});
                }
                tl.set('.c-step__option', {autoAlpha: 0});
                tl.set('.c-step__option', {left: '+=750'});
            }

            tl.to('.c-background-content', {
                duration: quickOne ? 0.2 : 0.5,
                delay: 0,
                autoAlpha: 0,
            }, quickOne ? 0 : 0.5);
            await tl;
        },
        async subStepFadeOut(): Promise<void> {
            const tl = gsap.timeline({
                delay: 0.3, onComplete: () => {
                    if (this.text) {
                        gsap.set('.c-step__text', {autoAlpha: 0});
                    }
                    gsap.set('.c-step__content, .c-step__option', {autoAlpha: 0});
                },
            });
            if (this.text) {
                tl.to('.c-step__text', {duration: 0.25, delay: 0, autoAlpha: 0}, 0)
            }
            tl.to('.c-step__option', {duration: 0.25, delay: 0, autoAlpha: 0}, 0)
            await tl;
        },
        async subStepFadeIn(): Promise<void> {
            if (this.text) {
                gsap.set('.c-step__text', {autoAlpha: 0});
            }
            gsap.set('.c-step__option', {autoAlpha: 0});
            gsap.to('.c-step__content', {duration: 0.75, autoAlpha: 1});
            const tl = gsap.timeline({delay: 0.3});
            if (this.text) {
                tl.to('.c-step__text', {duration: 0.25, delay: 0, autoAlpha: 1}, 0);
            }
            tl.to('.c-step__option', {duration: 0.25, delay: 0, autoAlpha: 1, stagger: 0.1}, 0);
            await tl;
            this.optionClicked = false;
        },
        // endregion

        // region Video Playing
        /**
         * Sets the necessary events to switch from intro to loop video.
         **/
        handleVideoPlaying() {
            const intro = this.videoIntro;
            const loop = this.videoLoop;
            if (loop) {
                loop.hidden = true;
            }

            if (intro) {
                if (loop) {
                    // this fixes the black flash on most device-browser combinations (except FF on Android)
                    loop.ontimeupdate = () => {
                        if (loop.currentTime > 0) {
                            loop.ontimeupdate = null;
                            intro.hidden = true;
                        }
                    }
                    intro.onended = () => {
                        loop.hidden = false;
                        loop.play();
                    }
                }
            } else {
                console.warn('intro video not found in handleVideoPlaying....');
            }
        },
        /**
         * This is necessary when coming back to start from another page (so with preloaded content)
         **/
        async setImages(): Promise<void> {
            if (this.$router.currentRoute.path === '/stap/start' && !this.imagesSet) {
                (this.$refs.refBoer as HTMLImageElement).src = this.mediaCache['images/logo-boer.png']?.src;
                (this.$refs.refGame as HTMLImageElement).src = this.mediaCache['images/logo-de-game.png']?.src;
                (this.$refs.refKiespijn as HTMLImageElement).src = this.mediaCache['images/logo-met-kiespijn.png']?.src;
                (this.$refs.refTak as HTMLImageElement).src = this.mediaCache['images/eikentak.png']?.src;
                gsap.set('.c-step__game, .c-step__kiespijn, .c-step__tak, .c-step__boer', {autoAlpha: 0});
                this.imagesSet = true;
            }

        },

        /**
         * Switch the videos (before moving to a new step)
         * This is done by toggling css classes and unloading source tags
         **/
        async switchVideos(newStepNo: string): Promise<void> {
            // remove current vid
            const container = this.$refs.refMediaContainer as HTMLDivElement;
            const $els = $('video:not(".preload")', container);
            $els.addClass('done');
            $els.attr('data-step', '');

            // unload the current video tags and remove event handlers
            $els.each(function () {
                ($(this).get(0) as HTMLMediaElement).pause();
                ($(this).get(0) as HTMLMediaElement).onended = null;
                ($(this).get(0) as HTMLMediaElement).ontimeupdate = null;
                $(this).children('source').attr('src', '');
                ($(this).get(0) as HTMLMediaElement).load();
            })

            // activate the videos that belong to the chosen option
            $('.vidIntro.preload[data-step="' + newStepNo + '"], .vidLoop.preload[data-step="' + newStepNo + '"]', container).removeClass('preload');

            // replace the 'done' tag with the 'preload' tag which allows us to free up the resources
            $('video.done', container).addClass('preload').removeClass('done');

            //clear the source of all other vids
            $('video.preload', container).each(function () {
                ($(this).get(0) as HTMLMediaElement).pause();
                ($(this).get(0) as HTMLMediaElement).onended = null;
                ($(this).get(0) as HTMLMediaElement).ontimeupdate = null;
                $(this).children('source').attr('src', '');
                ($(this).get(0) as HTMLMediaElement).load();
                $(this).addClass('free');
            });

            // remove all 'hidden' attributes from the video tags
            $('video', container).removeAttr('hidden');

            // save the video tags as properties of the step
            this.videoIntro = document.querySelector('.vidIntro:not(.preload)');
            this.videoLoop = document.querySelector('.vidLoop:not(.preload)');

            // load the videos
            this.videoIntro?.load();
            this.videoLoop?.load();

            //prepare the new video for playing
            this.handleVideoPlaying();
        },

        /**
         * Find a free video tag and full its properties with the properties of the given MediaItem
         **/
        async setVideo(item: MediaItem): Promise<void> {
            const cssSelector = '.free.' + item.cssClass.split(' ').join('.');
            const vid = document.querySelector(cssSelector) as HTMLMediaElement | null;

            if (vid === null) {
                console.warn('setVideo: video tag not found by selector "', cssSelector, '"');
                return;
            }

            if (item.loop) {
                vid.setAttribute('loop', 'loop');
            } else {
                vid.removeAttribute('loop');
            }
            vid.setAttribute('class', item.cssClass);
            vid.setAttribute('data-step', item.stepNo ?? '');
            vid.removeAttribute('hidden');

            const source = vid.childNodes[0] as HTMLSourceElement;
            source.setAttribute('src', item.src);

            // this resets the currently playing video and its events
            vid.load();
        },
        // endregion

        // region preloading content
        /**
         * Preload the assets of the possible next steps.
         * These are the videos of the steps all options of the curent step can lead to.
         * In case the current step has substeps, the step the last substep leads to is used.
         **/
        async preloadNextStep(): Promise<void> {
            let resolveLoad: (value?: void | PromiseLike<void> | undefined) => void;
            const loaderPromise = new Promise<void>((resolve) => {
                resolveLoad = resolve;
            })

            // get the next steps from the different options or last substep
            let assets: MediaItem[] = [];
            if (this.step.subSteps !== undefined && Object.keys(this.step.subSteps).length > 0) {
                Object.keys(this.step.subSteps).forEach((subStep) => {
                    if (this.step.subSteps !== undefined) {
                        this.step.subSteps[subStep].options?.forEach((option: OptionInfo) => {
                            const step = this.getStep(option.nextId);
                            step?.video?.split(',').forEach((vid: string, index: number) => {
                                assets.push({
                                    src: 'media/' + vid,
                                    cssClass: (index === 0 ? 'vidIntro' : 'vidLoop') + ' preload',
                                    loop: index !== 0,
                                    stepNo: option.nextId,
                                })
                            });
                        });
                    }
                });
            } else {
                this.step.options?.forEach((option) => {
                    this.getStep(option.nextId)?.video?.split(',').forEach((vid: string, index: number) => {
                        assets.push({
                            src: 'media/' + vid,
                            cssClass: (index === 0 ? 'vidIntro' : 'vidLoop') + ' preload',
                            loop: index !== 0,
                            stepNo: option.nextId,
                        })
                    });
                });
            }

            //remove duplicate sources from the array
            assets = assets.reduce((unique: MediaItem[], item: MediaItem) => {
                if (!unique.some(obj => obj.src === item.src && obj.stepNo === item.stepNo)) {
                    unique.push(item);
                }
                return unique;
            }, [])

            if (assets.length === 0) {
                // nothing to preload (when entering last step by url). return directly
                return;
            }

            //map the objects to urls for the fetch function
            const vidUrls = assets.map((asset) => {
                return asset.src;
            });
            const preload = Preload();
            preload.fetch(vidUrls);
            preload.oncomplete = () => {
                assets.forEach((asset: MediaItem) => {
                    this.setVideo(asset);
                })
                resolveLoad();
            }
            return loaderPromise;
        },

        /**
         * Preload the assets of the current step. This method is called on entering the app
         * and when a url navigation is done.
         **/
        async preloadAssets(stepNo: string, pretendNextStep = false): Promise<void> {
            let resolveLoad: (value?: void | PromiseLike<void> | undefined) => void;
            const loaderPromise = new Promise<void>((resolve) => {
                resolveLoad = resolve;
            })

            // get things to preload
            const step = this.getStep(stepNo);

            const preload = Preload();
            const loaderItems: string[] = [];
            this.mediaCache = {};

            step?.video?.split(',').forEach((fileName: string) => {
                loaderItems.push(`media/${fileName}`);
            });
            step?.image?.split(',').forEach((fileName: string) => {
                loaderItems.push(`images/${fileName}`);
            });
            step?.overlayImages?.split(',').forEach((fileName: string) => {
                loaderItems.push(`images/${fileName}`);
            });

            preload.fetch(loaderItems);
            preload.oncomplete = () => {
                if (stepNo === 'start') {
                    this.mediaCache['images/logo-boer.png'] = {
                        src: preload.getItemByUrl('images/logo-boer.png').url,
                        type: MediaType.image,
                        cssClass: '',
                    };
                    this.mediaCache['images/logo-de-game.png'] = {
                        src: preload.getItemByUrl('images/logo-de-game.png').url,
                        type: MediaType.image,
                        cssClass: '',
                    };
                    this.mediaCache['images/logo-met-kiespijn.png'] = {
                        src: preload.getItemByUrl('images/logo-met-kiespijn.png').url,
                        type: MediaType.image,
                        cssClass: '',
                    };
                    this.mediaCache['images/eikentak.png'] = {
                        src: preload.getItemByUrl('images/eikentak.png').url,
                        type: MediaType.image,
                        cssClass: '',
                    };
                }

                step?.video?.split(',').forEach((fileName: string, index: number) => {
                    let type = MediaType.videoIntro;
                    let cssClass = 'vidIntro';
                    if (index === 1) {
                        type = MediaType.videoLoop;
                        cssClass = 'vidLoop';
                    }
                    if (pretendNextStep) {
                        cssClass += ' preload';
                    }
                    this.setVideo({
                        src: preload.getItemByUrl([`media/${step?.video?.split(',')[index]}`]).url,
                        type: type,
                        loop: index !== 0,
                        stepNo: stepNo,
                        cssClass: cssClass,
                    });
                });

                resolveLoad();
            }
            return loaderPromise;
        },

        /**
         * Wait until both outro animation and asset loading are finished and then switch videos.
         **/
        async preloadAndSetAssets(stepNo: string, pretendNextStep = false) {
            await Promise.all([
                this.preloadAssets(stepNo, pretendNextStep),
                gsap.to(this.$refs.step, {duration: 0.2, autoAlpha: 0}),
            ]);
            await this.switchVideos(stepNo);
        },
        // endregion

        // region routing and page handling
        /**
         * Navigate to the nextStepNo.
         **/
        routerNavigateNext(nextStepNo: string, quickOne = false) {
            // if (!quickOne) {
            // wait until outro animation is finished
            this.outroAnimation(quickOne)
                .then(() => {
                    // switch videos here
                    if (!quickOne) {
                        this.switchVideos(nextStepNo);
                    }

                    this.nextAssetsLoaded = false;
                    this.imagesSet = false;
                    this.optionClicked = false;
                    this.$router.push(`/stap/${nextStepNo}`);
                })
            // } else {
            //     this.$router.push(`/stap/${nextStepNo}`);
            // }
        },

        /**
         * This method is called after a router change
         *
         * @param stepNo
         * @param prevStepNo
         */
        async stepChange(stepNo: string, prevStepNo?: string) {
            //check if preloading was done already. if not, we have to do it (this is the case when navigating via url)
            const step = this.getStep(stepNo);
            const mediaContainer = this.$refs.refMediaContainer as HTMLElement;

            // we need to preload the videos of the new step when they haven't been loaded yet (in case of url navigation
            if (step?.video !== undefined && $('.vidIntro[data-step="' + step.stepNo + '"]', mediaContainer).length === 0) {
                //reset all video tags, because we did not come here from
                $('video', mediaContainer)
                    .removeAttr('hidden')
                    .addClass('preload free')
                    .attr('data-step', '')
                    .removeAttr('loop')

                this.imagesSet = false;
                this.nextAssetsLoaded = false;

                await this.preloadAndSetAssets(stepNo, true);
            }

            this.setCurrentStep({
                stepNo: stepNo,
                prevStepNo: prevStepNo ?? '',
            } as CurrentStepInfo);

            this.setCurrentSubStep({
                text: undefined,
                options: undefined,
            } as SubStepInfo);
        },
        // endregion
    },
})
;
