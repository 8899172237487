



















import Vue, {PropType} from 'vue';
import {StepInfo} from '@/types/step';
import {gsap} from 'gsap';

export default Vue.extend({
    name: 'Overlay',
    components: {},
    props: {
        step: {
            type: Object as PropType<StepInfo>,
            required: true,
        },
    },
    mounted() {
        gsap.set('.c-step__overlay', {autoAlpha: 0})
        gsap.to('.c-step__overlay', {duration: 0.5, autoAlpha: 1})
    },
    computed: {},
});
