export enum MediaType {
    videoIntro,
    videoLoop,
    image,
}

export interface MediaItem {
    src: string;
    stepNo?: string;
    loop?: boolean;
    cssClass: string;
    type?: MediaType;
}

export interface MediaItemDict {
    [index: string]: MediaItem;
}
